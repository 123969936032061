import React from 'react'
import './Faqs.css'
import Faq from "react-faq-component";

const data = {
    title: "Frequently Asked Questions",
    rows: [
        {
            title: "Which wallet should I use?",
            content: `OC works with all crypto wallets. We recommend using Trust Wallet or Metamask Wallet.`,
        },
        {
            title: "When can I Buy OC ?",
            content: `OC Sale starts sharp on 20th March,2023 @ 9:30 AM GMT `,
        },
        {
            title: "Will I Get referral on referring a user to buy OC?",
            content: `You will receive a referral when a user uses your referral link & buys OC in any of the 6 sale round.`,
        },
        {
            title: "I can't Buy OC",
            content: `Please make sure that you are connected to Polygon Chain via Metamask or Trust wallet or any wallet that supports Polygon Chain & you have enough POL to pay for gas fee.`,
        },
        {
            title: "When can I Claim my OC?",
            content: `If you have purchased OC token during sale, You may claim it on 27th March, 2023 @ 12:30 PM GMT`,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#02ffff",
    rowTitleColor: "#ffffff",
    rowContentColor: 'grey',
    arrowColor: "#02ffff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
const Faqs = () => {
    return (
        <section className="faqs">
            <div className="oc_container">
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </section>
    )
}

export default Faqs