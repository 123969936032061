import React, { useEffect, useState } from 'react'
import './BalanceOverview.css'
import Countdown from "react-countdown";

import Round from "../../Assets/Abi/OcRound.json"
import Web3 from 'web3'
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { defaultWeb3, oracleAddress, usdtToken } from '../../config';
import { useLocation } from 'react-router';
import { localeFixed } from '../../config/helpers';
import toast, { Toaster } from 'react-hot-toast';


let web3 = defaultWeb3
const BalanceOverview = () => {
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    let roundAddr = String(queryParameters.get("id"))
    const [totalMaticInvested, settotalMaticInvested] = useState(0)
    const [myOcBalance, setmyOcBalance] = useState(0)
    const [usdtInvested, setusdtInvested] = useState(0)
    const [usdInvested, setusdInvested] = useState(0)
    const [totalOCAllotted, settotalOCAllotted] = useState(0)
    const [walletAddress, setwalletAddress] = useState(localStorage.getItem('walletaddress'))




    window.addEventListener('storage', () => {
        const walletAddress = localStorage.getItem('walletaddress');
        setwalletAddress(walletAddress)

    })



    window.addEventListener('details', () => {

        setmyOcBalance(localStorage.getItem("oc"))
        settotalMaticInvested(localStorage.getItem("maticinvested"))
        setusdtInvested(localStorage.getItem("usdtInvested"))
        setusdInvested(localStorage.getItem("totalInvested"))
        settotalOCAllotted(localStorage.getItem("totalOCAllotted"))

        localStorage.getItem("totalInvested")
    })


    

    // Check Claim status  
    const [chekClaim, setChekClaim] = useState("Claim")

    async function checkOcBoughtStatus() {
        if (walletAddress) {
            let signer
         
            const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
            signer = metaMaskProvider.getSigner();

            const roundInstance = new ethers.Contract(roundAddr, Round, signer)
            const userData = await roundInstance.userData(walletAddress)
            const totalOCAllotted = ethers.utils.formatEther(userData.totalOCBought._hex)

            let userAmount = await roundInstance.ocBought(walletAddress)
            userAmount = ethers.utils.formatEther(userAmount)

            // console.log(userAmount, "userAmount");
            const checkUser = (Number(userAmount) === 0)
            // console.log(checkUser);
            

            if (checkUser === false) {

                if (totalOCAllotted > 0) {
                    setChekClaim("Claim Pending")

                }

            } else {

                if (Number(totalOCAllotted) !== 0) {
                    setChekClaim("Claimed")
                }

               
            }
        }
        else {
            setChekClaim("Claim")
        }
    }


    // claim OC button 
    async function ClaimOC() {

        let signer

        const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
        signer = metaMaskProvider.getSigner();

        const roundInstance = new ethers.Contract(roundAddr, Round, signer)
        let userAmount = await roundInstance.ocBought(walletAddress)
        userAmount = ethers.utils.formatEther(userAmount)
        console.log(userAmount, "userAmount");
        const checkUser = (Number(userAmount) === 0)
        console.log(checkUser);
        //  -> button disable

        if (checkUser === false) {


            const test = await roundInstance.claim()
            await test.wait()

        } else {
            if (Number(totalOCAllotted) !== 0) {
                setChekClaim("Claimed")
                toast.error("You have already claimed your OC")
            }
            else{
                toast.error("You have not Purchased OC, Unable to claim!")
            }

            

            
        }

    }

    useEffect(() => {
        const intervalId = setInterval(() => {

            checkOcBoughtStatus()

        }, 4000)
        return () => clearInterval(intervalId); //This is important
    },);


    // call checkOCBounghtStatus when walletAddress change
    useEffect(() => {
        checkOcBoughtStatus()
    }, [walletAddress])


    useEffect(() => {
        checkOcBoughtStatus()
    }, [])

    // async function ClaimOC() {

    //     let signer

    //     const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
    //     signer = metaMaskProvider.getSigner();

    //     const roundInstance = new ethers.Contract(roundAddr, Round, signer)

    //     //check user amount for claim 
    //     let userAmount = await roundInstance.ocBought(walletAddress)
    //     userAmount = ethers.utils.formatEther(userAmount)
    //     // console.log(userAmount, "userAmount");
    //     const checkUser = (Number(userAmount) === 0)
    //     // console.log(checkUser);


    //     if (checkUser == false) {

    //         const test = await roundInstance.claim()
    //         toast('Transaction is inprogress')

    //         await test.wait()
    //         toast.success('Transaction done')

    //     } else {

    //         toast.error("You have not Purchased OC, Unable to claim!")

    //     }

    // }


    //     
    return (
        <section className="balanceOverview">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="oc_container">
                <div className="title">
                    <h2>OC Balance Overview</h2>
                </div>
                <div className="balanceOverviewBox">
                    <ul>
                        <li><p>Your OC Balance </p><strong>{localeFixed(myOcBalance) || 0} OC</strong></li>
                        <li><p>POL Invested </p><strong>{localeFixed(totalMaticInvested) || 0} {parseFloat(totalMaticInvested) > 0 ? `( $${localeFixed((parseFloat(usdInvested) - parseFloat(usdtInvested)) / (parseFloat(totalMaticInvested) === 0 ? 1 : parseFloat(totalMaticInvested)))} )` : ''}</strong></li>
                        <li><p>USDT Invested </p><strong>${localeFixed(usdtInvested) || 0}</strong></li>
                        <li><p>Investment in USD </p><strong>${localeFixed(usdInvested) || 0}</strong></li>
                        <li><p>OC Bought </p><strong>{localeFixed(totalOCAllotted) || 0} OC</strong></li>

                        <li><p>Claim After </p><strong className='countDown'>
                            {/* 27 March 06:29 PM GMT */}

                            <Countdown date={1679951004 * 1000} />


                        </strong></li>
                    </ul>
                    <div className="buttonGrp">
                        <button className="oc_btn" onClick={ClaimOC} >{chekClaim}</button>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default BalanceOverview