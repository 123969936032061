import React, { useEffect, useState } from 'react'
import './BuySell.css'
import ScrollAnimation from 'react-animate-on-scroll';
import Round from "../../Assets/Abi/OcRound.json"
import Web3 from 'web3'
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { useLocation } from 'react-router';
import toast, { Toaster } from 'react-hot-toast';
import { defaultWeb3, ocToken } from '../../config';

import { rpcUrl } from '../../config';


let web3 = new Web3(rpcUrl)

const BuySell = () => {

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)


    async function addTestnetToMetamask() {
       
        try {
          // check if the chain to connect to is installed
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x89' }], // chainId must be in hexadecimal numbers
        });
        toast.success('Already Exist')
      
      } catch (error) {
       
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x89',
                  chainName: "Polygon Chain",
                  rpcUrls: ['https://polygon-rpc.com'],
                  nativeCurrency: {
                      name: "POL",
                      symbol: "POL", // 2-6 characters long
                      decimals: 18
                  },
                },
              ],
            });
            // alert("Chain Added!")
          } catch (addError) {
              // alert(JSON.stringify(addError));
            console.error(addError);
          }
        // }
        // alert(JSON.stringify(error));
        console.error(error);
      }
      }

    let roundAddr = String(queryParameters.get("id"))
    const [ocsold, setocsold] = useState()
    const [maticRaised, setmaticRaised] = useState()
    const [usdtRaised, setusdtRaised] = useState()
    const [roundOCLimit, setroundOCLimit] = useState()

      async function dataForRound() {
       
       //signer condition start 
    let provider = defaultWeb3
    let signer
    if (window.ethereum) {
      try {
        //  console.log("enter");
        const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await metaMaskProvider.listAccounts();
        if (( accounts).length) {
          //  console.log("condittion match");
          provider = metaMaskProvider;
          signer = metaMaskProvider.getSigner();
        }
        else {
          //  console.log("else call");
          // signer = (walletAddress && window.ethereum) ? ((new ethers.providers.Web3Provider(window.ethereum)).getSigner()):
          signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
        }
      } catch (exception) {
        //  console.error('Unable to list accounts', exception);
      }
    }
    else {
      // console.log("else call");

      signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
    }
    //  console.log(signer, "after");
    // signer condition closed
          const roundInstance = new ethers.Contract(roundAddr, Round, signer)
try {
  let roundOCLimit = await roundInstance.roundLimit()
          roundOCLimit = ethers.utils.formatEther(roundOCLimit)
          setroundOCLimit(roundOCLimit)

       
          let totalRaised = await roundInstance.totalRaised()
          let totalmaticRaised = ethers.utils.formatEther(totalRaised[1])
          setmaticRaised(totalmaticRaised)

          let totalusdtRaised = ethers.utils.formatEther(totalRaised[0])
          setusdtRaised(totalusdtRaised)
      
          let ocsold = await roundInstance.ocSold()
          ocsold = ethers.utils.formatEther(ocsold)
          // console.log(ocsold);
          setocsold(ocsold)
} catch (error) {
  
}
          
      
  
      }

      async function addToMetamask() {
        try{
          toast("+ Adding Token")
        await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: ocToken,
                    symbol: 'OC',
                    decimals: 18,
                    image: "https://sale.oxchange.finance/static/media/oc-change-logo.0f6b7218e79c1bfb11e2.png",
                },
            },
        }).catch((e) => {
            toast(e.data ? e.data.message : e.message)
        })
        }catch(e){
        toast(e.data ? e.data.message : e.message)
        }
        }

        // console.log(ocsold,"now");
        useEffect(() => {
          dataForRound()
        },[])
      useEffect(() => {
        const intervalId = setInterval(() => {
        
            dataForRound()
        
        }, 1000)

        return () => clearInterval(intervalId); //This is important
      },);
      
    return (
        <section className="buySell">
              <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className="oc_container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buySellbox">
                            <ScrollAnimation animateIn='fadeIn'
                                initiallyVisible={true}
                                animateOnce={true}>
                                <div className="fillBox">
                                
                                    <span style={{ width: `${roundAddr.toLowerCase() === "0x35b87b6f8Af00A337d09C8BB9c57fCab4e01B162".toLowerCase() ? '100' :roundAddr.toLowerCase() === "0x37354c01db60d34f058c87799Dfa4140f5222194".toLowerCase() ? '100' :roundAddr.toLowerCase() === "0x242896715fe4b2De88cfabf3266459Cc84Ff1C5c".toLowerCase() ? '100' :roundAddr.toLowerCase() === "0xB24b0A062516aFa2ae4c670488782d65B4C016CD".toLowerCase() ? '100' :roundAddr.toLowerCase() === "0x7a12Dbb701fA6990ac0b4dC4c9675882Cd447E36".toLowerCase() ? '100' :roundAddr.toLowerCase() === "0xbAC1ab501F429E6Da800cDAEC115fBDFea32cF0e".toLowerCase() ? '100' :false}%` }}></span>
                                </div>
                                <div className="oc_row align-items-center space-between">
                                    <p>OC SOLD</p>
                                    <span>Hardcap</span>
                                </div>
                                <div class="buttonGrp">
                                    <button class="oc_btn"onClick={addTestnetToMetamask} >Add Polygon Mainnet</button>
                                    <button class="oc_btn" onClick={addToMetamask} >Add To Metamask</button>
                                    { roundAddr.toLowerCase() === "0xB24b0A062516aFa2ae4c670488782d65B4C016CD".toLowerCase() && <a href="https://polygonscan.com/address/0xB24b0A062516aFa2ae4c670488782d65B4C016CD" style={{ textDecoration: "none" }} class="oc_btn" target="_blank"> View smart Contract </a>}
                                   
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BuySell