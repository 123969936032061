import React, { useEffect, useState } from 'react'
import './BuyOc.css'
import Web3 from 'web3'
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import saleAbi from "../../Assets/Abi/OcSale.json"
import Round from "../../Assets/Abi/OcRound.json"
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router';
import {  oracleAddress, defaultWeb3,  usdtToken } from '../../config';




let web3 = defaultWeb3

const BuyOc = () => {


    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const [SaleSupply, setSaleSupply] = useState(0)
    const [OCPrice, setOCPrice] = useState(0)
    const [refferalCode, setrefferalCode] = useState(localStorage.getItem("refcode"))


    let walletaddress = localStorage.getItem("walletaddress")


    const [maticBalance, setmaticBalance] = useState()
    const [usdtBalance, setusdtBalance] = useState()


    useEffect(() => {
        const intervalId = setInterval(() => {
            setmaticBalance(localStorage.getItem("pol"))
            setusdtBalance(localStorage.getItem("usdt"))
            // let walletaddress = localStorage.getItem("walletaddress")

        }, 1000)

        return () => clearInterval(intervalId); //This is important
    },);





    let roundAddr = String(queryParameters.get("id"))


    // console.log("roundAddr",roundAddr);

    const [flag, setflag] = useState(false)
    const [usdt, setUsdt] = useState()

    const [matic, setMatic] = useState()
    const [usdtToOc, setusdtToOc] = useState(0)
    const [maticToOc, setmaticToOc] = useState(0)




    let oracleInstance;
    const oracleAddr = "0xeb71190D374B593C7AA81617b5FC0683CE82F22f"
    const usdtAddress = usdtToken


    // fetch user details 
    const [ocsold, setocsold] = useState()
    const [maticRaised, setmaticRaised] = useState()
    const [usdtRaised, setusdtRaised] = useState()
    const [roundOCLimit, setroundOCLimit] = useState()

    //    //signer condition start 
    let provider = defaultWeb3
    let signer

    async function dataForRound() {


        //signer condition start 
        provider = defaultWeb3
        let signer
        if (window.ethereum) {
            try {

                const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
                const accounts = metaMaskProvider.listAccounts();
                if ((await accounts).length) {

                    provider = metaMaskProvider;
                    signer = metaMaskProvider.getSigner();
                }
                else {

                    signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                }
            } catch (exception) {
                console.error('Unable to list accounts', exception);
            }
        }
        else {
            // console.log("else call");

            signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
        }
        //  console.log(signer, "after");
        // signer condition closed


        try {
            const roundInstance = new ethers.Contract(roundAddr, Round, signer)
            let roundOCLimit = await roundInstance.roundLimit()
            roundOCLimit = ethers.utils.formatEther(roundOCLimit)
            setroundOCLimit(roundOCLimit)
            // console.log("limit", roundOCLimit);
            let totalRaised = await roundInstance.totalRaised()
            let totalmaticRaised = ethers.utils.formatEther(totalRaised[1])
            setmaticRaised(totalmaticRaised)
            let totalusdtRaised = ethers.utils.formatEther(totalRaised[0])
            setusdtRaised(totalusdtRaised)
            // console.log("MATIC ", ethers.utils.formatEther(totalRaised[1]))
            // console.log("USDT", ethers.utils.formatEther(totalRaised[0]))
            let ocsold = await roundInstance.ocSold()
            ocsold = ethers.utils.formatEther(ocsold)
            setocsold(ocsold)
            // console.log("ocsold", ocsold);
        } catch (error) {

        }





    }
    async function userdetails() {
        //signer condition start 
        provider = defaultWeb3
        let signer
        if (window.ethereum) {
            try {
                //   console.log("enter");
                const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
                const accounts = await metaMaskProvider.listAccounts();
                if ((await accounts).length) {
                    //   console.log("condittion match");
                    provider = metaMaskProvider;
                    signer = metaMaskProvider.getSigner();
                }
                else {
                    //   console.log("else call");
                    // signer = (walletAddress && window.ethereum) ? ((new ethers.providers.Web3Provider(window.ethereum)).getSigner()):
                    signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                }
            } catch (exception) {
                console.error('Unable to list accounts', exception);
            }
        }
        else {
            // console.log("else call");

            signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
        }
        //   console.log(signer, "after");
        // signer condition closed

        const roundInstance = new ethers.Contract(roundAddr, Round, signer)
        try {
            if (walletaddress) {
                const userData = await roundInstance.userData(walletaddress)

                const totalMaticInvested = ethers.utils.formatEther(userData.totalMaticInvested)
                const totalUsdtInvested = ethers.utils.formatEther(userData.totalUSDTInvested)
                const totalInvested = ethers.utils.formatEther(userData.totalUSDInvested._hex)
                const totalOCAllotted = ethers.utils.formatEther(userData.totalOCBought._hex)
           

                //Local storage value
                window.localStorage.setItem("maticinvested", totalMaticInvested)
                window.localStorage.setItem("usdtInvested", totalUsdtInvested)
                window.localStorage.setItem("totalInvested", totalInvested)
                window.localStorage.setItem("totalOCAllotted", totalOCAllotted)
                window.dispatchEvent(new Event("details"));




                let totalInvestment = await roundInstance.investmentCount(walletaddress)



                totalInvestment = ethers.utils.formatEther(totalInvestment) * 10 ** 18
            }
        } catch (error) {

        }



    }

    let usdtInst;
    let saleInst;

    const saleAddr = oracleAddress


    const tokenABI = [
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "initialSupply",
                    "type": "uint256"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "value",
                    "type": "uint256"
                }
            ],
            "name": "Approval",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "value",
                    "type": "uint256"
                }
            ],
            "name": "Transfer",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                }
            ],
            "name": "allowance",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "approve",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "balanceOf",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "decimals",
            "outputs": [
                {
                    "internalType": "uint8",
                    "name": "",
                    "type": "uint8"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "subtractedValue",
                    "type": "uint256"
                }
            ],
            "name": "decreaseAllowance",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "spender",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "addedValue",
                    "type": "uint256"
                }
            ],
            "name": "increaseAllowance",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "name",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "symbol",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "totalSupply",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "transfer",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "transferFrom",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]

    // take approval 
    async function checkApprove() {
        try {
            await window.ethereum.enable();


            provider.send("eth_requestAccounts", [])

            const walletAddress = await signer.getAddress()

            saleInst = new ethers.Contract(saleAddr, saleAbi, signer)

            // console.log("signer", signer);
            usdtInst = new ethers.Contract(usdtAddress, tokenABI, signer)
            // console.log("usdtInst", usdtInst);
            try {
                let allowance = await usdtInst.allowance(walletAddress, roundAddr)


                if (allowance > 0) {
                    setflag(true)

                } else {

                    const test = await usdtInst.approve(roundAddr, ethers.constants.MaxUint256)
                    await test.wait()
                    setflag(true)
                    check()

                }
            }
            catch (e) {
                // console.warn(e)

            }
        } catch (e) {
            // console.warn(e)

        }
    }

    // check allowance
    async function check() {
      
            if (window.ethereum) {
                try {
                    //   console.log("enter");
                    const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
                    const accounts = await metaMaskProvider.listAccounts();
                    if ((accounts).length) {
                        
                        try {
                            await window.ethereum.enable();
                            const provider = new ethers.providers.Web3Provider(window.ethereum)
                            provider.send("eth_requestAccounts", [])
                            const signer = (
                                window.ethereum ? (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
                                    : new ethers.providers.Web3Provider(web3.currentProvider)
                            )
                            const walletAddress = await signer.getAddress()

                            saleInst = new ethers.Contract(saleAddr, saleAbi, signer)


                            let usdtInst = new ethers.Contract(usdtAddress, tokenABI, signer)

                            try {

                                let allowance = await usdtInst.allowance(walletAddress, roundAddr)


                                if (allowance > 0) {
                                    setflag(true)

                                }
                            }
                            catch (e) {
                                // console.warn(e)

                            }
                        } catch (e) {
                            // console.warn(e)

                        }
                    }


                }catch (e) {
                    // console.warn(e)
        
                }


            }
            
    }



        async function convertmatic(e, isFull) {
            if (e >= 0) {
                if (isFull && e > 0.01) {
                    e = e - 0.01
                } else if (isFull) {
                    e = 0
                }
                let userInput = String(e || 0)
                setMatic(e)

               
                // const provider = new ethers.providers.Web3Provider(window.ethereum)
                // provider.send("eth_requestAccounts", [])
                
                //signer condition start 
                provider = defaultWeb3
                let signer
                if (window.ethereum) {
                    try {
                        //    console.log("enter");
                        const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
                        const accounts = metaMaskProvider.listAccounts();
                        if ((await accounts).length) {
                            //    console.log("condittion match");
                            provider = metaMaskProvider;
                            signer = metaMaskProvider.getSigner();
                        }
                        else {
                            //    console.log("else call");
                            // signer = (walletAddress && window.ethereum) ? ((new ethers.providers.Web3Provider(window.ethereum)).getSigner()):
                            signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                        }
                    } catch (exception) {
                        console.error('Unable to list accounts', exception);
                    }
                }
                else {
                    // console.log("else call");

                    signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                }
                //    console.log(signer, "after");
                // signer condition closed

                const roundInstance = new ethers.Contract(roundAddr, Round, signer)
                try {

                    let getOCAmount = await roundInstance.getOCAmount(ethers.utils.parseEther(userInput), true)
                    getOCAmount = ethers.utils.formatEther(getOCAmount._hex)
                    setmaticToOc(getOCAmount)



                } catch (e) {
                    // console.warn(e)

                }
            }
            else {
                setMatic("")

            }

        }
        async function buymatic(e) {

            if (e !== "" && e >= 0) {

                let userInput = String(e || 0)
                setMatic(e)

                if (Number(ocsold) <= Number(roundOCLimit)) {
                 
                    // const provider = new ethers.providers.Web3Provider(window.ethereum)
                    // provider.send("eth_requestAccounts", [])
                    //signer condition start 
                    let provider = defaultWeb3
                    let signer
                    if (window.ethereum) {
                        try {
                            //    console.log("enter");
                            const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
                            const accounts = metaMaskProvider.listAccounts();
                            if ((await accounts).length) {
                                //    console.log("condittion match");
                                provider = metaMaskProvider;
                                signer = metaMaskProvider.getSigner();
                            }
                            else {
                                //    console.log("else call");
                                // signer = (walletAddress && window.ethereum) ? ((new ethers.providers.Web3Provider(window.ethereum)).getSigner()):
                                signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                            }
                        } catch (exception) {
                            //    console.error('Unable to list accounts', exception);
                        }
                    }
                    else {
                        // console.log("else call");

                        signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                    }
                    //    console.log(signer, "after");
                    // signer condition closed



                    const roundInstance = new ethers.Contract(roundAddr, Round, signer)
                    try {
                        let getUSDAmount = await roundInstance.getUSDAmount(ethers.utils.parseEther(userInput))
                        getUSDAmount = ethers.utils.formatEther(getUSDAmount._hex)



                        if (getUSDAmount >= 10) {
                            try {

                                if (refferalCode && refferalCode !== "null" || refferalCode !== null) {
                                    const test = await roundInstance.buy(refferalCode, { value: ethers.utils.parseEther(userInput) })
                                    toast.success('Transaction is Inprogress')
                                    await test.wait()
                                    setMatic("")
                                    toast.success('Transaction done')
                                }
                                else {
                                    const test = await roundInstance.buy(ethers.constants.AddressZero, { value: ethers.utils.parseEther(userInput) })
                                    toast.success('Transaction is Inprogress')
                                    await test.wait()
                                    setMatic("")
                                    toast.success('Transaction done')
                                }

                            } catch (e) {

                                toast.error(e.reason ? e.reason : (e.data ? e.data.message : e.message))
                            }

                            userdetails()

                        }
                        else {
                            toast.error('Min amount should be $10')
                        }

                    } catch (e) {
                        // console.warn(e)
                        toast.error(e.reason ? e.reason : (e.data ? e.data.message : e.message))
                    }
                }
                else {
                    toast.error('Round is full !')
                    setMatic("")
                }
            }
            else {
                toast.error('Invalid amount')
                setMatic("")
            }


        }
        async function convertusdt(e) {

            if (e >= 0) {
                let userInput = String(e || 0)
                setUsdt(e)

              
                // const provider = new ethers.providers.Web3Provider(window.ethereum)
                // provider.send("eth_requestAccounts", []) //signer condition start 
                provider = defaultWeb3
                let signer
                if (window.ethereum) {
                    try {
                        //    console.log("enter");
                        const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
                        const accounts = metaMaskProvider.listAccounts();
                        if ((await accounts).length) {
                            //    console.log("condittion match");
                            provider = metaMaskProvider;
                            signer = metaMaskProvider.getSigner();
                        }
                        else {
                            //    console.log("else call");
                            // signer = (walletAddress && window.ethereum) ? ((new ethers.providers.Web3Provider(window.ethereum)).getSigner()):
                            signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                        }
                    } catch (exception) {
                        console.error('Unable to list accounts', exception);
                    }
                }
                else {
                    // console.log("else call");

                    signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                }
                //    console.log(signer, "after");
                // signer condition closed

                const roundInstance = new ethers.Contract(roundAddr, Round, signer)
                try {
                    let getOCAmount = await roundInstance.getOCAmount(ethers.utils.parseEther(userInput), false)
                    getOCAmount = ethers.utils.formatEther(getOCAmount._hex)
                    setusdtToOc(getOCAmount)


                }
                catch (e) {
                    // console.warn(e)
                }
            }
            else {

                setUsdt("")
            }
            // use parseUnits with 6 as the value, because polygon USDT has 6 decimals

        }
        async function buyusdt(e) {

            if (e !== "" && e >= 0) {
                let userInput = String(e || 0)
                setUsdt(e)
                if (Number(ocsold) <= Number(roundOCLimit)) {
                
                    // const provider = new ethers.providers.Web3Provider(window.ethereum)
                    // provider.send("eth_requestAccounts", [])
                    //signer condition start 
                    let provider = defaultWeb3
                    let signer
                    if (window.ethereum) {
                        try {
                            //    console.log("enter");
                            const metaMaskProvider = new ethers.providers.Web3Provider(window.ethereum);
                            const accounts = metaMaskProvider.listAccounts();
                            if ((await accounts).length) {
                                //    console.log("condittion match");
                                provider = metaMaskProvider;
                                signer = metaMaskProvider.getSigner();
                            }
                            else {
                                //    console.log("else call");
                                // signer = (walletAddress && window.ethereum) ? ((new ethers.providers.Web3Provider(window.ethereum)).getSigner()):
                                signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                            }
                        } catch (exception) {
                            console.error('Unable to list accounts', exception);
                        }
                    }
                    else {
                        // console.log("else call");

                        signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider)
                    }
                    //    console.log(signer, "after");
                    // signer condition closed

                    const roundInstance = new ethers.Contract(roundAddr, Round, signer)
                    try {
                        // await roundInstance.getOCAmount(ethers.utils.parseEther(userInput), false)
                        let getUSDAmount = await roundInstance.getUSDAmount(ethers.utils.parseEther(userInput))
                        getUSDAmount = ethers.utils.formatEther(getUSDAmount._hex)

                        // console.log("USD", getUSDAmount);

                        if (getUSDAmount >= 10) {

                            if (refferalCode === "null" || refferalCode === null || refferalCode === "") {


                                const test = await roundInstance.buyWithUsdt(ethers.utils.parseUnits(userInput, 6), ethers.constants.AddressZero)
                                toast.success('Transaction is Inprogress')
                                await test.wait()

                                setUsdt("")

                                //   console.log("transaction done");
                                toast.success('Transaction done')


                            }
                            else {

                                const test = await roundInstance.buyWithUsdt(ethers.utils.parseUnits(userInput, 6), refferalCode)
                                toast.success('Transaction is Inprogress')
                                await test.wait()

                                setUsdt("")
                                // console.log("transaction done");
                                toast.success('Transaction done')

                            }
                        }
                        else {
                            toast.error('Min amount should be $10')
                        }
                    }
                    catch (e) {
                        // console.warn(e)
                        toast.error(e.reason ? e.reason : (e.data ? e.data.message : e.message))

                    }
                }
                else {
                    toast.error('Round is full !')
                    setMatic("")


                }
            }
            else {
                toast.error('Invalid amount')
                setUsdt("")
            }

            // use parseUnits with 6 as the value, because polygon USDT has 6 decimals

        }

        function roundfull() {
            toast.error('Sale has Ended !')
        }

        useEffect(() => {
            check()
            userdetails()
            dataForRound()
        }, [])

        useEffect(() => {
            const intervalId = setInterval(() => {

                userdetails()
                dataForRound()

            }, 4000)

            return () => clearInterval(intervalId); //This is important
        },);

        return( <></>)


        return (
            <section className="buyOc">
                <Toaster
                    position="bottom-right"
                    reverseOrder={false}
                />


                <div className="oc_container">
                    <div className="title">
                        <h2>BUY OC</h2>
                        {/* <button onClick={userdetails}>user details </button> */}
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mb-4">
                            <div className="buyOcbox">
                                <strong className="topttl">Enter USDT Amount <span>(Available: {usdtBalance || 0.000000} )</span></strong>
                                <input type="number" onChange={(e) => convertusdt(e.target.value)} value={usdt} placeholder="0.00" />
                                <div className="oc_row align-items-center space-between">
                                    <p>You will receive <b>{usdtToOc || 0.0000}</b> OC</p>
                                    <span>Min $10</span>
                                </div>
                                <div class="buttonGrp">
                                    {flag ||
                                        <button class="oc_btn" disabled onClick={checkApprove}>Approve USDT</button>
                                    }
                                    <button class="oc_btn" onClick={() => convertusdt(usdtBalance)}>Max Invest</button>
                                    <button class="oc_btn"  onClick={roundfull}>Invest USDT</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 mb-4">
                            <div className="buyOcbox">
                                <strong className="topttl">Enter MATIC Amount <span>(Available: {maticBalance || 0.000000} )</span></strong>
                                <input type="number" min={0} onChange={(e) => convertmatic(e.target.value, false)} value={matic} placeholder="0.00" />
                                <div className="oc_row align-items-center space-between">
                                    <p>You will receive <b>{maticToOc || 0.0000}</b> OC</p>
                                    <span>Min $10</span>
                                </div>
                                <div class="buttonGrp">

                                    <button class="oc_btn" onClick={() => convertmatic(maticBalance, true)}>Max Invest</button>
                                    <button class="oc_btn"  onClick={roundfull}>Invest MATIC</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    export default BuyOc